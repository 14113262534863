import { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router";
import { Input, Button, Radio } from "antd";
// import { SearchImgGoods } from "api/home";
import "./searchTab.less";
import { times } from "utils/getTime";

const SearchTabStore = (props) => {
  const { query, setQuery, color } = props;
  const history = useHistory();
  const [queryd, setQueryd] = useState({});
  const [price, setPrice] = useState(true);
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(query.keywords);
  }, [query]);

  const search = (order) => {
    if (order === -1) {
      setQuery(() => {
        query.min_price = queryd.min_price;
        query.max_price = queryd.max_price;
        query.page = 1;
        return { ...query };
      });
    } else {
      if (
        history.location.pathname.indexOf("search") > -1 &&
        history.location.pathname.indexOf("searchImg") === -1
      ) {
        if (order === 3 && query.order === order) {
          setPrice(true);
          order = 4;
        } else if (order === 4 && query.order === order) {
          setPrice(false);
          order = 3;
        }
        let search = "";
        if (history.location.search) {
          let arr = history.location.search.split("?")[1].split("&");
          arr.forEach((k) => {
            if (k.indexOf("order") > -1) {
              search += "order=" + order + "&";
            } else {
              if (k) search += k + "&";
            }
          });
          if (search.indexOf("order") === -1) search += "order=" + order + "&";
          search = search.substring(0, search.length - 1);
        } else {
          search = "order=" + order;
        }
        history.push(`/search/?${search}`);
      } else {
        // history.push(`/merchant/?${search}`);
        if (order === 3 && query.order === order) {
          setPrice(true);
          order = 4;
        } else if (order === 4 && query.order === order) {
          setPrice(false);
          order = 3;
        }
        setQuery(() => {
          query.order = order;
          return { ...query };
        });
      }
    }
  };
  const goMerchant = () => {
    // 2023/3/14 检索关键词清空分类
    if (history.location.pathname.indexOf("merchant") > -1) {
      setQuery(() => {
        query.store_class_id = "";
        query.keywords = value;
        return { ...query };
      });
      history.replace(`/merchant/${history.location.pathname.split("/")[2]}`);
    }
  };

  const onChange = (type, e) => {
    setQueryd(() => {
      if (+e.target.value > -1) {
        queryd[type] = +e.target.value;
        if (e.target.value === "") queryd[type] = "";
      } else {
        queryd[type] = "";
      }
      return { ...queryd };
    });
  };
  // const onChanged = (page, pageSize) => {
  //   setQuery(() => {
  //     query.page = page;
  //     query.limit = pageSize;
  //     return { ...query };
  //   });
  // };

  const [top, setTop] = useState(0);
  useMemo(() => {
    function updateSize() {
      setTop(document.documentElement.scrollTop);
    }
    window.addEventListener("scroll", updateSize);
    return () => window.removeEventListener("scroll", updateSize);
  }, []);

  // const customRequest = async (file) => {
  //   const formData = new FormData();
  //   formData.append("image", file.file);
  //   const res = await SearchImgGoods(formData);
  //   if (res.ResponseStatus === 0) {
  //     if (res.datas.goods_list.length > 0) {
  //       // setImglist([...res.datas.goods_list]);
  //     }
  //   }
  // };

  return (
    <div className="searchTabStore" style={{ position: "relative" }}>
      <div
        style={{
          width: "1152px",
          padding: "12px 12px",
          position: top > 698 ? "fixed" : "absolute",
          top: top > 698 ? "-2px" : "-12px",
          zIndex: 98,
          background: "#f1f1f1",
          transform: "translateX(-12px)",
        }}
      >
        <div className="sort-inner flex-center flex-b">
          <div className="flex-center">
            <div className="sorts flex-center">
              <ul>
                <li
                  className={`searchSort flex-center flex-centerY pointer ${
                    query.order === 2 ? "choRed" : ""
                  }`}
                  onClick={() => {
                    search(2);
                  }}
                >
                  默认
                  <span className="lowSort"></span>
                </li>
                <li
                  className={`searchSort flex-center flex-centerY pointer ${
                    query.order === 9 ? "choRed" : ""
                  }`}
                  value="1"
                  onClick={() => {
                    search(9);
                  }}
                >
                  浏览人气
                  <span className="lowSort"></span>
                </li>
                <li
                  className={`searchSort flex-center flex-centerY pointer ${
                    query.order === 10 ? "choRed" : ""
                  }`}
                  value="1"
                  onClick={() => {
                    search(10);
                  }}
                >
                  下载
                  <span className="lowSort"></span>
                </li>
                <li
                  className={`searchSort flex-center flex-centerY pointer ${
                    query.order === 5 ? "choRed" : ""
                  }`}
                  value="1"
                  onClick={() => {
                    search(5);
                  }}
                >
                  销量
                  <span className="lowSort"></span>
                </li>
                {/* <li
                  className={`searchSort flex-center flex-centerY pointer ${
                    query.order === 4 ? "choRed" : ""
                  }`}
                  onClick={() => {
                    search(4);
                  }}
                >
                  价格
                  <span className="lowSort"></span>
                </li> */}
                {price ? (
                  <li
                    className={`searchSort flex-center flex-centerY pointer ${
                      query.order === 4 ? "choRed" : ""
                    }`}
                    onClick={() => {
                      search(4);
                    }}
                  >
                    价格
                    <span className="lowSort"></span>
                  </li>
                ) : (
                  <li
                    className={`searchSort flex-center flex-centerY pointer ${
                      query.order === 3 ? "choRed" : ""
                    }`}
                    onClick={() => {
                      search(3);
                    }}
                  >
                    价格
                    <span className="upSort"></span>
                  </li>
                )}
                <li
                  className={`searchSort flex-center flex-centerY pointer ${
                    query.order === 6 ? "choRed" : ""
                  }`}
                  onClick={() => {
                    search(6);
                  }}
                >
                  上新时间
                  <span className="lowSort"></span>
                </li>
                <li className="inputSep">
                  <div className="inputRge">
                    <Input
                      type="text"
                      placeholder="￥最低价"
                      id="search_price_begin"
                      value={queryd.min_price}
                      onChange={(e) => {
                        onChange("min_price", e);
                      }}
                    />
                    <i></i>
                    <Input
                      type="text"
                      placeholder="￥最高价"
                      id="search_price_end"
                      value={queryd.max_price}
                      onChange={(e) => {
                        onChange("max_price", e);
                      }}
                    />
                  </div>
                  <div className="priceRange" id="search_priceRange_submit">
                    <p
                      onClick={() => {
                        search(-1);
                      }}
                    >
                      确定
                    </p>
                    <div
                      onClick={() => {
                        setQueryd(() => {
                          queryd.min_price = "";
                          queryd.max_price = "";
                          return { ...queryd };
                        });
                      }}
                    >
                      清空
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="pager flex-center flex-centerY fz12">
            {/* <Pagination
              size="small"
              current={query.page}
              pageSize={query.limit}
              total={total}
              showSizeChanger={false}
              showLessItems={true}
              onChange={onChanged}
            /> */}

            <span>商品名称/尺寸：</span>
            <div className="search-input search-store-input flex">
              <Input
                className="input ml4 mr4 fz12"
                bordered
                placeholder="请输入商品关键字"
                size="small"
                value={value}
                allowClear
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onPressEnter={goMerchant}
              />
              {/* <div className="search-input-icon">
                <Upload customRequest={customRequest} showUploadList={false}>
                  <span className="iconfont icon-zhaoxiangji primary-color" />
                </Upload>
              </div> */}
              <Button onClick={goMerchant} className="fz12">
                搜索
              </Button>
            </div>
          </div>
        </div>
        <div className="sort-inner flex-center" style={{ paddingLeft: 16, borderTop: 'none' }}>
          <Radio.Group
            defaultValue="all"
            options={[
              { label: "全部", value: "all" },
              { label: "近3天新品", value: 3 },
              { label: "近7天新品", value: 7 },
              { label: "近30天新品", value: 30 },
            ]}
            onChange={(e) => {
              setQuery(() => {
                if (e.target.value === "all") {
                  query.start_time = "";
                  query.end_time = "";
                } else {
                  query.start_time = times(e.target.value)?.[0];
                  query.end_time = times(e.target.value)?.[1] + 1;
                }
                query.page = 1;
                return { ...query };
              });
            }}
          />

          <div style={{ height: 10, borderLeft: "1px solid #e4e4e4", margin: '0 24px' }}></div>

          <Radio.Group
            options={[
              { label: "按系列展示", value: 2 },
              { label: "按花型展示", value: 1 },
            ]}
            value={query.is_color || color}
            onChange={(e) => {
              setQuery(() => {
                query.is_color = e.target.value;
                return { ...query };
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default SearchTabStore;
