import { useEffect, useRef, useState } from "react";
import { Image } from "antd";

// css
import "./h5.less";

// util
import Storage from "utils/storage";

// img
import code from "assets/mer/code.png";

const H5 = () => {
  const [data, setData] = useState({});

  useEffect(() => {
    if (global.private) {
      let href = document.domain.replace("zyterp", "h5.zyterp");
      if (!href.includes("zyterp")) {
        href = href.replace("www", "h5");
      }
      window.location.href = "http://" + href;
    }
    if (Object.keys(data).length === 0) {
      setData(() => {
        if (Storage.getSetting()) return JSON.parse(Storage.getSetting());
        return {};
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  function getDeviceType() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/iPod/i)
    ) {
      return "ios";
    } else if (userAgent.match(/Android/i)) {
      return "android";
    } else {
      return "unknown";
    }
  }

  function isWechat() {
    var userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('micromessenger') !== -1;
  }

  function isWeibo() {
    var ua = window.navigator.userAgent.toLowerCase();
    return ua.match(/WeiBo/i) === "weibo";
  }

  const onOpenAppBefore = () => {
    const brower = {
      brower: isWechat() ? "weixin" : isWeibo() ? "weibo" : "xxx",
      sys: getDeviceType(),
    };
    const appSrc = {
      android: "",
      ios: "zhaoyuantou://GoodDetailVC",
    };
    const downSrc = {
      android: "https://a.app.qq.com/o/simple.jsp?pkgname=com.fangzhifu.findsource",
      ios: "https://apps.apple.com/cn/app/找源头/id1592293746",
    };
    openApp(brower, appSrc, downSrc);
  };

  function openApp(brower, appSrc, downSrc) {
    if(isWechat() || isWeibo())return alert('请前往浏览器中打开')
    if (brower.sys === "ios") {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        window.location.href = downSrc?.ios;
      }, 3000);
      window.location.href = appSrc?.ios;
    } else {
      clearTimeout(timer.current);
      timer.current = setTimeout(() => {
        window.location.href = downSrc?.android;
      }, 3000);
      window.location.href = appSrc?.android;
    }
  }

  const timer = useRef();
  const watchVisibility = () => {
    if (!document.hidden) {
      clearTimeout(timer.current);
    }
  };
  useEffect(() => {
    watchVisibility();
    window.addEventListener("visibilitychange", watchVisibility);
    return () => {
      window.removeEventListener("visibilitychange", watchVisibility);
    };
  }, []);

  return (
    <div className="h5">
      <div
        className="open"
        style={{ top: isWechat() ? "58vh" : "" }}
        onClick={onOpenAppBefore}
      >
        打开{data.mall_name}
      </div>
      {isWechat() ? (
        <div className="code">
          <Image width={100} height={100} src={code} preview={false}></Image>
          <div>长按识别二维码,下载APP</div>
        </div>
      ) : (
        <div
          className="down"
          onClick={() => {
            window.open(
              "https://a.app.qq.com/o/simple.jsp?pkgname=com.fangzhifu.findsource"
            );
          }}
        >
          下载{data.mall_name}
        </div>
      )}

      <div className="pri_name">南通找源头网络科技有限公司</div>
    </div>
  );
};
export default H5;
