import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./index.less";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const SwiperTop = (props) => {
  const { data = [] } = props;
  const [swiper, setSwiper] = useState(2500);
  // const [showAll, setShowAll] = useState(false);
  const onMouseEnter = () => {
    if (data?.length === 1) return;
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    if (data?.length === 1) return;
    swiper.autoplay.start();
  };

  // const firstSwiperSlide =
  //   data?.length > 0 ? (
  //     <div
  //       className={data?.[0]?.template_url ? "pointer" : ""}
  //       style={{
  //         height: "100%",
  //         background: `url(${data?.[0]?.template_img}) center / auto 100%`,
  //       }}
  //       onClick={() => {
  //         if (!data?.[0]?.template_url) return;
  //         window.open(data?.[0]?.template_url);
  //       }}
  //     ></div>
  //   ) : null;

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowAll(true);
  //   }, 8000);
  // }, []);

  return (
    <div
      className="new_home-swiper-top"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {/* {showAll ? (
        data?.length === 1 ? (
          firstSwiperSlide
        ) : ( */}
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={data?.length > 1}
        loopAdditionalSlides={2}
        loopedSlides={3}
        autoplay={data?.length > 1}
        effect={"fade"}
        className="mySwiper"
        onSwiper={setSwiper}
      >
        {data?.map?.((k, i) => (
          <SwiperSlide key={i}>
            <div
              className={k.template_url ? "pointer" : ""}
              style={{
                height: "100%",
                background: `url(${k.template_img}) center / auto 100%`,
              }}
              onClick={() => {
                if (!k.template_url) return;
                window.open(k.template_url);
              }}
            ></div>
          </SwiperSlide>
        ))}
      </Swiper>
      {/* )
      ) : (
        firstSwiperSlide
      )} */}
    </div>
  );
};
export default SwiperTop;
