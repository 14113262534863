import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Image, Input, Spin, message, Upload } from "antd";

// css
import "./index.less";

// utils
import Storage from "utils/storage";

// api
import { GetKeyWord, AddKeyWord, DelKeyWord } from "api/search";
import { Ossupload } from "api/common";
import { GetNewRecommendConfig } from "api/home";

// module
import SearchLeft from "./SearchLeft";

// img
import search3 from "assets/new_imgs/search3.png";
import camera from "assets/new_imgs/camera2.png";

const { Search } = Input;

const StateList = ["商品", "厂家", "以图搜款", "找同款神器"];
const PlaceholderList = {
  1: "请输入关键字 或粘贴图片搜索",
  2: "请输入图片路径 或CTRL + V粘贴图片搜款",
  3: "请输入关键字 或粘贴图片搜索",
};

/**
 *
 * @param { searchForm } props
 * searchForm: 路由参数
 * @returns
 */
const Header = (props) => {
  const { searchForm } = props;
  const history = useHistory();
  const inputRef = useRef(null);

  const [active, setActive] = useState(0);
  const [placeholder, setPlaceholder] = useState(PlaceholderList[1]);

  // 获取基础配置参数 ---- start ----
  const [hotSearchList, setHotSearchList] = useState([]);
  const [member, setMember] = useState({});
  useEffect(() => {
    const getInit = () => {
      const set_json = Storage?.getSetting?.();
      if (set_json) {
        const form = JSON.parse(set_json);
        const arr = form?.hot_search?.split?.(",");
        setHotSearchList(arr);
      } else {
        setTimeout(() => {
          getInit();
        }, 200);
      }
      const json = Storage?.getUser?.();
      if (json) {
        const form = JSON.parse(json);
        setMember(form);
        getKeyWord();
      }
    };
    getInit();
  }, []);
  // 获取基础配置参数 ---- end ----

  // 获取广告 ---- strat ----
  const [dataFirst, setDataFirst] = useState(false);
  const [dataMap, setDataMap] = useState(new Map());
  const getRecommendConfig = async () => {
    setDataFirst(true);
    const res = await GetNewRecommendConfig({
      keys: "search_left,search_right,search",
    });
    if (!res) return;
    if (res.ResponseStatus) return;
    const arr = res?.datas || [];
    const map = new Map();
    arr.forEach((k) => {
      if (k.key !== "other") {
        map.set(k.key, k);
      }
    });
    setDataMap(map);
  };
  const dataFn = (key) => {
    const arr = (dataMap?.get?.(key) || {})?.template_common || [];
    return arr;
  };
  // 获取广告 ---- end ----

  // 获取搜索记录 ---- start ----
  const [historySearch, setHistorySearch] = useState([]);
  const getKeyWord = async () => {
    const res = await GetKeyWord({ limit: 4 });
    const arr = res?.datas || [];
    setHistorySearch(arr);
  };
  const onClear = async () => {
    const res = await DelKeyWord({ id: "1", type: "1" });
    if (!res) return;
    if (res.ResponseStatus) return message.error(res.ResponseMsg);
    setHistorySearch([]);
  };
  // 获取搜索记录 ---- end ----

  // 切换tab
  const chooseActive = (i) => {
    // setKeywords("");
    setActive(i);
    setPlaceholder();
    if (i === 3) {
      if (history?.location?.pathname === "/searchassistant") {
        setPlaceholder(PlaceholderList[i]);
        return;
      }
      window.open("/searchassistant");
      return;
    } else {
      setPlaceholder(PlaceholderList?.[i] || PlaceholderList?.[1]);
    }
  };

  // 搜索框
  const [keywords, setKeywords] = useState("");
  const [keyFocus, setKeyFocus] = useState(false);
  const [hotSearchVisible, setHotSearchVisible] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [isBurl, setIsBurl] = useState(false);
  useEffect(() => {
    const form = { ...searchForm };
    setActive(+form.active || 0);
    if (form.keywords) setKeywords(form.keywords);
  }, [searchForm]);
  const onSearch = (value) => {
    if (value?.includes?.("http") && active === 2) return goImgSearch(value);
    setKeywords(value);
    if (member?.token && value) {
      AddKeyWord({ keyword: value });
      setHistorySearch((arr) => {
        arr.unshift({ keyword: value });
        return [...arr];
      });
    }
    const form = { ...searchForm };
    form.active = active;
    form.keywords = value;

    let search_str = "search";
    const arr = [];
    Object.keys(form)?.forEach?.((key) => {
      if (form[key]) arr.push(key + "=" + form[key]);
    });
    if (arr?.length) search_str += "?";
    search_str += arr?.join?.("&");
    if (history?.location?.pathname === "/search") {
      history.replace(search_str);
    } else {
      window.open(search_str);
    }
  };

  // 跳转搜索
  const goImgSearch = (image) => {
    if (history?.location?.pathname === "/searchImg") {
      history.replace(`/searchImg?imgurl=${image}`);
    } else {
      window.open(`/searchImg?imgurl=${image}`);
    }
  };

  // 粘贴搜索
  const [previewImg, setPreviewImg] = useState("");
  const customRequest = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file.file);
    reader.onload = function (e) {
      setPreviewImg(e.target.result);
    };

    setSpinning(true);
    const formData = new FormData();
    formData.append("file", file.file);
    const res = await Ossupload(formData);
    setSpinning(false);
    setHotSearchVisible(false);
    goImgSearch(res?.datas?.[0]?.url);
  };

  // 粘贴操作
  const onPaste = (event) => {
    const data = event.clipboardData || window.clipboardData;
    console.log("data?.types?.[0]", data?.types?.[0], data?.items);

    if (data?.types?.[0] === "text/plain" && active === 2) {
      goImgSearch(data.getData("Text"));
    } else if (data?.items?.length) {
      for (let i = 0; i < data.items.length; i++) {
        if (data.items[i].type?.indexOf?.("image") !== -1) {
          const file = data.items[i].getAsFile();
          customRequest({ file });
          break;
        }
      }
    }
  };

  return (
    <div className="new_home-header">
      <div className="container flex-center">
        {/* logo */}
        <div
          className="logo"
          style={{ marginRight: 43 }}
          onClick={() => {
            history.push("/");
          }}
        >
          <img
          className="pointer"
            style={{ width: 192, height: 65, objectFit: 'contain' }}
            src="https://oss.fangzhifu.com/new_hong.png"
            alt="找源头"
          />
        </div>

        {/* 搜索 */}
        <div className="new_home-search">
          {/* tab */}
          <div className="search-top flex fw700 _666">
            {StateList?.map((k, i) => (
              <span
                className={active === i ? "active" : ""}
                key={i}
                onClick={() => {
                  chooseActive(i);
                }}
              >
                {k}
              </span>
            ))}
          </div>
          {/* 搜索框 */}
          <div className="flex-center" style={{ width: "100%" }}>
            <div
              className={`search-input flex1 ${keyFocus ? "focus" : ""} `}
              onMouseLeave={() => {
                setHotSearchVisible(false);
              }}
            >
              <Search
                ref={inputRef}
                className={`input`}
                bordered={false}
                placeholder={
                  history.location.pathname.indexOf("searchImg") > -1
                    ? "请输入图片路径 或CTRL + V粘贴图片搜款"
                    : placeholder
                }
                prefix={
                  <img
                    src={search3}
                    className="ml10"
                    style={{ width: 16 }}
                    alt=""
                  />
                }
                enterButton="搜索"
                size="small"
                value={keywords}
                onSearch={onSearch}
                onChange={(e) => {
                  setKeywords(e.target.value);
                }}
                onFocus={() => {
                  setKeyFocus(true);
                  setHotSearchVisible(true);
                  if (!dataFirst) getRecommendConfig();
                }}
                onBlur={() => {
                  setKeyFocus(false);
                  if (!isBurl) setHotSearchVisible(false);
                }}
                onClick={() => {
                  setHotSearchVisible(true);
                }}
                onPasteCapture={onPaste}
              />
              <div
                className="searchloading"
                style={{
                  opacity: spinning ? "1" : "0",
                  height: spinning ? "120px" : "0",
                }}
              >
                {previewImg && (
                  <Image
                    className="pointer"
                    width={80}
                    preview={false}
                    src={previewImg}
                    alt="找源头"
                  />
                )}{" "}
                <Spin
                  spinning={spinning}
                  style={{ marginLeft: "60px" }}
                  tip="搜索同款中..."
                />
              </div>
              <div
                className="history-search none"
                style={{
                  display: hotSearchVisible && !spinning ? "flex" : "none",
                }}
                onMouseEnter={() => {
                  setIsBurl(true);
                }}
                onMouseLeave={() => {
                  setIsBurl(false);
                }}
              >
                <div className="history-search-left">
                  <div
                    className="mbottom10 history-search-main"
                    style={{
                      height:
                        (dataFn("search")?.length / 4) * 118 - 204 + 42 + "px",
                    }}
                  >
                    <div className="left-title flex-b">
                      <div className="sb_title">历史记录</div>
                      <div className="sb_tools">
                        <i className="sb_del" title="清除搜索记录">
                          <span
                            className="iconfont icon-shanchu icon_text pointer"
                            onClick={onClear}
                          ></span>
                        </i>
                      </div>
                    </div>
                    <div className="sb_body">
                      {historySearch?.map?.((k, i) => (
                        <div
                          className="sb_item pointer owt"
                          style={{ width: "100%" }}
                          key={i}
                          onClick={() => {
                            onSearch(k.keyword);
                          }}
                        >
                          {k.keyword}
                        </div>
                      ))}
                    </div>
                  </div>

                  {/* 左侧广告 */}
                  <div className="flex-center">
                    <SearchLeft datasLeft={dataFn("search_left")} />
                    <SearchLeft
                      className="ml10"
                      datasLeft={dataFn("search_right")}
                    />
                  </div>
                </div>
                <div className="history-search-right">
                  <div className="right-title">热门推荐</div>
                  <div className="search-goods flex-w">
                    {dataFn("search")?.map?.((k, i) => (
                      <div
                        className="search-goods-item pointer"
                        key={i}
                        onClick={() => {
                          window.open(k.template_url);
                        }}
                      >
                        <img src={k.template_img} alt="" />
                        <div className="search-goods-item-tag">
                          {k.store_name}
                        </div>
                        <div className="search-goods-item-name owt">
                          {k.template_name}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <Upload customRequest={customRequest} showUploadList={false}>
              <div className="serchByImg flex-center pointer">
                <img width={24} src={camera} alt="" />
                <span className="ml4">以图搜款</span>
              </div>
            </Upload>
          </div>

          {/* 热词 */}
          <div className="search-bottom flex fz14 mtop4">
            {hotSearchList?.map?.((k, i) => (
              <span
                style={{ whiteSpace: "nowrap" }}
                className="mr12 pointer"
                key={i}
                onClick={() => {
                  onSearch(k);
                }}
              >
                {k}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
