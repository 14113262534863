import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./index.less";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const SwiperBanner = (props) => {
  const { data = [] } = props;
  const [swiper, setSwiper] = useState(2500);
  const onMouseEnter = () => {
    if (data?.length === 1) return;
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    if (data?.length === 1) return;
    swiper.autoplay.start();
  };

  return (
    <div
      className="new_home-new_shop"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {data?.length === 1 ? (
        <div
          className={data?.[0]?.template_url ? "pointer" : ""}
          style={{
            height: "100%",
            background: `url(${data?.[0]?.template_img}?x-oss-process=image/resize,m_lfit,h_600,w_600) center`,
          }}
          onClick={() => {
            if (!data?.[0]?.template_url) return;
            window.open(data?.[0]?.template_url);
          }}
        ></div>
      ) : (
        <Swiper
          slidesPerView={1}
          spaceBetween={1}
          loop
          loopAdditionalSlides={2}
          loopedSlides={3}
          pagination={true}
          navigation={true}
          autoplay
          className="mySwiper"
          onSwiper={setSwiper}
        >
          {data?.map?.((k, i) => (
            <SwiperSlide key={i}>
              <div
                className="pointer"
                onClick={() => {
                  if (!k.template_url) return;
                  window.open(k.template_url);
                }}
              >
                <img
                  className="image"
                  src={`${k.template_img}?x-oss-process=image/resize,m_lfit,h_600,w_600`}
                  alt={k.template_name}
                />
                <div className="goods_info">
                  <div className="store_name">{k.store_name}</div>
                  <div className="goods_name owt" title={k.template_name}>
                    {k.template_name}
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
};
export default SwiperBanner;
