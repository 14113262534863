import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router";
import { PlusSquareFilled, MinusSquareFilled } from "@ant-design/icons";
import "./storeClass.less";
import StoreGoods from "./StoreGoods";

const ContShopLeft = (props) => {
  const history = useHistory();
  const {
    store_id,
    classes,
    setQuery,
    query,
    active1,
    setActive1,
    active2,
    setActive2,
  } = props;

  const [actives, setActives] = useState({});
  useEffect(() => {
    if (classes?.length === 1) {
      choose1(0);
    }
  }, [classes]); // eslint-disable-line react-hooks/exhaustive-deps

  const choose1 = (num) => {
    setActive1(num);
    setActive2(-1);
    if (num > -1) {
      // 2023/3/14 检索分类清空关键词
      setQuery(() => {
        query.keywords = "";
        query.store_class_id = classes[num].class_id;
        query.page = 1;
        return { ...query };
      });
    } else {
      setQuery(() => {
        query.keywords = "";
        query.store_class_id = "";
        query.page = 1;
        return { ...query };
      });
    }
    if (num > -1) {
      let search = "";
      if (history.location.search) {
        let arr = history.location.search.split("?")[1].split("&");
        arr.forEach((k) => {
          if (k.indexOf("i1") > -1) {
            search += "i1=" + num + "&";
          } else if (k.indexOf("i2") > -1) {
            return;
          } else {
            if (k) search += k + "&";
          }
        });
        if (search.indexOf("i1") === -1) search += "i1=" + num + "&";
        search = search.substring(0, search.length - 1);
      } else {
        search = "&i1=" + num;
      }
      if (global.private) {
        if (search) {
          history.push(`/?${search}`);
        } else {
          history.push(`/`);
        }
      } else {
        if (search) {
          history.push(
            `/merchant/${history.location.pathname.split("/")[2]}?${search}`
          );
        } else {
          history.push(`/merchant/${history.location.pathname.split("/")[2]}`);
        }
      }
    } else {
      let search = "";
      if (history.location.search) {
        let arr = history.location.search.split("?")[1].split("&");
        arr.forEach((k) => {
          if (k.indexOf("i1") > -1) {
            return;
          } else if (k.indexOf("i2") > -1) {
            return;
          } else {
            if (k) search += k + "&";
          }
        });
        search = search.substring(0, search.length - 1);
      }
      if (global.private) {
        if (search) {
          history.push(`/?${search}`);
        } else {
          history.push(`/`);
        }
      } else {
        if (search) {
          history.push(
            `/merchant/${history.location.pathname.split("/")[2]}?${search}`
          );
        } else {
          history.push(`/merchant/${history.location.pathname.split("/")[2]}`);
        }
      }
    }
  };
  const choose2 = (num, num0) => {
    if (num0 !== undefined) setActive1(num0);
    setActive2(num);
    if (num > -1) {
      setQuery(() => {
        query.store_class_id =
          classes?.[num0 !== undefined ? num0 : active1]?.subs?.[num]?.class_id;
        query.page = 1;
        return { ...query };
      });
    } else {
      setQuery(() => {
        query.store_class_id =
          classes[num0 !== undefined ? num0 : active1].class_id;
        query.page = 1;
        return { ...query };
      });
    }
    if (num > -1) {
      let search = "";
      if (history.location.search) {
        let arr = history.location.search.split("?")[1].split("&");
        arr.forEach((k) => {
          if (k.indexOf("i2") > -1) {
            search += "i2=" + num + "&";
          } else if (k.indexOf("i1") > -1 && num0 !== undefined) {
            search += "i1=" + num0 + "&";
          } else {
            if (k) search += k + "&";
          }
        });
        if (search.indexOf("i2") === -1) search += "i2=" + num + "&";
        search = search.substring(0, search.length - 1);
      } else {
        search = "&i2=" + num;
      }
      if (global.private) {
        history.push(`/?${search}`);
      } else {
        history.push(
          `/merchant/${history.location.pathname.split("/")[2]}?${search}`
        );
      }
    } else {
      let search = "";
      if (history.location.search) {
        let arr = history.location.search.split("?")[1].split("&");
        arr.forEach((k) => {
          if (k.indexOf("i2") > -1) {
            return;
          } else if (k.indexOf("i1") > -1 && num0 !== undefined) {
            search += "i1=" + num0 + "&";
          } else {
            if (k) search += k + "&";
          }
        });
        search = search.substring(0, search.length - 1);
      }
      if (global.private) {
        history.push(`/?${search}`);
      } else {
        history.push(
          `/merchant/${history.location.pathname.split("/")[2]}?${search}`
        );
      }
    }
  };

  const [top, setTop] = useState(0);
  useMemo(() => {
    function updateSize() {
      setTop(document.documentElement.scrollTop);
    }
    window.addEventListener("scroll", updateSize);
    return () => window.removeEventListener("scroll", updateSize);
  }, []);

  const [classLength, setClassLength] = useState(0)

  return (
    <div className="contShopLeftss" style={{ position: "relative" }}>
      <div
        style={{
          position: top > 696 ? "fixed" : "absolute",
          top: top > 696 ? "10px" : "0",
          zIndex: 98,
        }}
      >
        <StoreGoods store_id={store_id} setClassLength={setClassLength} />

        <div
          className="recommend fz12"
          style={{ height: `calc(100vh - ${classLength > 1 ? 420 : classLength ? 320 : 20}px)` }}
        >
          <h3 className="rng-leftp1">店内分类</h3>
          <div
            style={{
              maxHeight: `calc(100% - 60px)`,
              overflowY: "auto",
            }}
          >
            <div style={{ padding: "6px 10px 2px" }}>商品分类</div>
            <div className="proRank">
              <div>
                <div className="proRankTop flex-center flex-b">
                  <span className="owt flex-center" style={{ width: "200px" }}>
                    <MinusSquareFilled className="_ccc fz15 mtop2" />
                    <i
                      className={
                        active1 === -1
                          ? "pointer active hover-red"
                          : "pointer hover-red"
                      }
                      onClick={(e) => {
                        choose1(-1);
                      }}
                    >
                      全部
                    </i>
                  </span>
                </div>
              </div>
            </div>
            <div className="proRank">
              {classes?.map?.((item, index) => (
                <div key={index}>
                  {item.class_id?.includes?.("time") &&
                  !classes[index - 1]?.class_id?.includes?.("time") ? (
                    <div style={{ padding: "6px 10px 2px" }}>按日期分类</div>
                  ) : null}
                  <div
                    className="proRankTop flex-center flex-b"
                    onClick={() => {
                      setActives(() => {
                        actives[index] = !actives[index];
                        return { ...actives };
                      });
                    }}
                  >
                    <span className="flex">
                      <span>
                        {item?.subs?.length > 0 ? (
                          actives[index] ? (
                            <MinusSquareFilled className="_ccc fz15 mtop2" />
                          ) : (
                            <PlusSquareFilled className="_ccc fz15 mtop2" />
                          )
                        ) : (
                          <MinusSquareFilled className="_ccc fz15 mtop2" />
                        )}
                      </span>
                      <i
                        className={
                          active1 === index
                            ? "pointer active hover-red owt2"
                            : "pointer hover-red owt2"
                        }
                        style={{ width: "200px" }}
                        onClick={(e) => {
                          choose1(index);
                        }}
                      >
                        {item.class_name}
                      </i>
                    </span>
                  </div>
                  <ul
                    style={{
                      display:
                        !actives[index] ||
                        (actives[index] === undefined &&
                          active1 === index &&
                          (active2 === 0 || active2 > 0))
                          ? "block"
                          : "none",
                    }}
                  >
                    {item.subs?.map?.((item1, index1) => (
                      <li
                        className="li owt2"
                        key={index1}
                        style={{ width: "250px" }}
                      >
                        <span
                          className={
                            active1 === index && active2 === index1
                              ? "pointer active hover-red"
                              : "pointer hover-red"
                          }
                          onClick={(e) => {
                            choose2(index1, index);
                          }}
                        >
                          {item1.class_name}
                        </span>
                        {/* <code>&gt;</code> */}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContShopLeft;
