import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import SwiperCore, {
  Autoplay,
  Pagination,
  Navigation,
  EffectFade,
} from "swiper/core";

// css
import "./index.less";
import "swiper/swiper.min.css";
import "swiper/components/effect-fade/effect-fade.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

SwiperCore.use([Autoplay, Pagination, Navigation, EffectFade]);

const BigBanner = (props) => {
  const { data } = props;
  const [swiper, setSwiper] = useState(2500);
  const [active, setActive] = useState(0);

  const onMouseEnter = (i) => {
    setActive(i);
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    swiper.autoplay.start();
  };
  const onSlideChangeTransitionStart = () => {
    setActive(swiper.realIndex || 0);
  };

  return (
    <div className="new_home-big-banner">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop
        initialSlide={1}
        autoplay={{ delay: 4000 }}
        effect={"fade"}
        pagination={false}
        navigation={true}
        className="mySwiper"
        onSwiper={setSwiper}
        onSlideChangeTransitionStart={onSlideChangeTransitionStart}
      >
        {data?.map?.((k, i) => (
          <SwiperSlide
            key={i}
            onMouseEnter={() => {
              onMouseEnter(i);
            }}
            onMouseLeave={() => {
              onMouseLeave();
            }}
          >
            <div
              className="mySwiper-item"
              style={{
                backgroundImage: `url(${k.template_img})`,
                backgroundSize: "100% 100%",
              }}
            >
              {k.radio === 1 ? (
                <div
                  className="box-items"
                  onClick={() => {
                    window.open(k.template_url);
                  }}
                ></div>
              ) : (
                <div
                  className="box-items"
                  style={{
                    gridTemplateRows: k.radio > 2 ? "1fr 1fr" : "1fr",
                  }}
                >
                  {(k.radio === 2
                    ? [0, 1]
                    : k.radio === 5
                    ? [0, 1, 2, 3]
                    : [0, 1, 2]
                  )?.map?.((k1, i1) => (
                    <div
                      className={`box-item ${
                        k.radio === 3 && i1 === 0
                          ? "boxItem1"
                          : k.radio === 4 && i1 === 2
                          ? "boxItem2"
                          : ""
                      }`}
                      key={i1}
                      onClick={() => {
                        window.open(k?.value?.[i1]?.template_url);
                      }}
                    >
                      <img
                        src={`${k?.value?.[i1]?.template_img}?x-oss-process=image/resize,m_lfit,h_600,w_600`}
                        alt={k?.value?.[i1]?.template_name}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div
        className="spots"
        onMouseLeave={() => {
          onMouseLeave();
        }}
      >
        {data?.map?.((k, i) => (
          <div
            className={+active === +i ? "spot spot_active" : "spot"}
            key={i}
            onMouseEnter={() => {
              swiper.slideToLoop(i, 200);
            }}
          >
            <span>{k.template_name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};
export default BigBanner;
