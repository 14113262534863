import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import "./index.less";

import SwiperCore, { Autoplay, Pagination, Navigation } from "swiper/core";
SwiperCore.use([Autoplay, Pagination, Navigation]);

const SwiperBanner = (props) => {
  const { data = [], ossSize = 800 } = props;
  const [swiper, setSwiper] = useState(2500);
  const onMouseEnter = () => {
    if (data?.length === 1) return;
    swiper.autoplay.stop();
  };
  const onMouseLeave = () => {
    if (data?.length === 1) return;
    swiper.autoplay.start();
  };
  return (
    <div
      className="new_home-module2-banner"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Swiper
        slidesPerView={1}
        spaceBetween={1}
        loop={data?.length > 1}
        loopAdditionalSlides={2}
        loopedSlides={3}
        pagination={data?.length > 1}
        navigation={data?.length > 1}
        autoplay={data?.length > 1}
        className="mySwiper"
        onSwiper={setSwiper}
      >
        {data?.map?.((k, i) => (
          <SwiperSlide key={i}>
            <div
              className="module2-right-item pointer"
              onClick={() => {
                if (!k.template_url) return;
                window.open(k.template_url);
              }}
            >
              <img
                className="image"
                src={`${k.template_img}?x-oss-process=image/resize,m_lfit,h_${ossSize},w_${ossSize}`}
                alt={k.template_name}
              />

              <div className="template-info">
                <div className="store-name textC">{k.store_name}</div>
                <div className="template-name owt">{k.template_name}</div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
export default SwiperBanner;
