import { RightOutlined } from "@ant-design/icons";

// less
import "./index.less";

// module
import MainBottom from "../../components/MainBottom";

const Module8 = (props) => {
  const { datas } = props;

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };
  return (
    <div className="new_home-module8">
      <div className="module8-top">
        <div className="module8-right">
          {templateFn("right")?.map?.((k, i) => (
            <div className="module8-right-item pointer" key={i}>
              <img
                src={`${k.template_img}?x-oss-process=image/resize,m_lfit,h_600,w_600`}
                alt={k.template_name}
                onClick={() => {
                  window.open(k.template_url);
                }}
              />

              <div className="template-infos flex-center flex-b">
                <div className="flex-center">
                  <div className="store_name">{k.store_name}</div>
                  <div className="template-name owt flex1">
                    {k.template_name}
                  </div>
                </div>
                <div className="right_icon">
                  <RightOutlined />
                </div>
                <div className="template-items">
                  {k.value?.map?.((k1, i1) => (
                    <div
                      className="template-item"
                      key={i1}
                      onClick={() => {
                        window.open(k1.template_url);
                      }}
                    >
                      <img
                        src={`${k1.template_img}?x-oss-process=image/resize,m_lfit,h_600,w_600`}
                        alt={k1.template_name}
                      />
                      <div className="template-info">
                        <div className="goods-price">
                          <span className="money">¥</span>
                          {k1.goods_price}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="new_home_main_bottom">
        <MainBottom data={templateFn("main_bottom_left")} />
        <MainBottom data={templateFn("main_bottom_right")} />
      </div>
    </div>
  );
};
export default Module8;
