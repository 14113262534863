// less
import "./index.less";

// module
import Banner from "./Banner";
import MainBottom from "../../components/MainBottom";

const Module2 = (props) => {
  const { datas } = props;

  const templateFn = (key) => {
    return (datas[key] || { template_common: [] })?.template_common || [];
  };

  return (
    <div className="new_home-module2">
      <div className="module2-top">
        <div className="module2-right">
          {templateFn("right")?.map?.((k, i) => (
            <div key={i}>
              <Banner data={k.value || [k]} ossSize={600} />
            </div>
          ))}
        </div>
      </div>
      <div className="new_home_main_bottom">
        <MainBottom data={templateFn("main_bottom_left")} />
        <MainBottom data={templateFn("main_bottom_right")} />
      </div>
    </div>
  );
};
export default Module2;
